import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useDebounce } from '../../hooks';
import Input from '../Input/Input';
import styles from './SearchInput.module.scss';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  value?: string;
  onSearch: (value: string) => void;
}

const SearchInput = ({
  placeholder = 'Search',
  value = '',
  onSearch,
  className,
}: SearchInputProps) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [setText, value]);

  const { debounce } = useDebounce({ delay: 1000 });
  const handleOnChange = (val: string) => {
    setText(val);
    debounce(() => {
      onSearch(val);
    });
  };

  return (
    <Input
      className={cx(className, styles.searchInput)}
      placeholder={placeholder}
      value={text}
      onChange={e => handleOnChange(e.currentTarget.value)}
    />
  );
};

export default SearchInput;
